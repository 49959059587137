import React from "react"
import { navigate } from "gatsby-link"
import styled from 'styled-components'
import tachyons from 'tachyons-components'

const Label = tachyons(`label`)`
db lh-copy f6
`
const StyledLabel = styled(Label)`
  font-weight: 400;
  color: var(--textDarkMode);
`

const Input = tachyons(`input`)`
pa2 input-reset w-100 mb3 br2
`
const StyledInput = styled(Input)`
background-color: var(--bgAccent);
color: var(--textPrimary);
border: 1px solid var(--buttonBorder);
background-image: linear-gradient(135deg, hsla(0, 0%, 100%, 0.09), hsla(0, 0%, 100%, 0.05));
box-shadow: 0 2px 4px 0 var(--buttonBoxShadow);
`

const Textarea = tachyons(`textarea`)`
pa2 input-reset w-100 mb3 br2
`
const StyledTextarea = styled(Textarea)`
background-color: var(--bgAccent);
color: var(--textPrimary);
border: 1px solid var(--buttonBorder);
background-image: linear-gradient(135deg, hsla(0, 0%, 100%, 0.09), hsla(0, 0%, 100%, 0.05));
box-shadow: 0 2px 4px 0 var(--buttonBoxShadow);
`

const Button = tachyons(`button`)`
button w-100 w-auto-l no-underline bn br2 ph4 pv3 dib mr3-ns bg-primary button-primary tc fw5 pointer
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Contact = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/about?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
        <div>
        <div className="hr-top mt5 mb5"></div>
        <h3 className="mt4 mb2 fw6">Contact me</h3>
        <p className="content lh-copy">You can also reach me on twitter, <a className="link link-external" href="https://twitter.com/ajmalafif" target="_blank" rel="noopener noreferrer">@ajmalafif</a>.</p>
        <form
          name="contact"
          method="post"
          action="/about/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          <StyledLabel htmlFor={"name"} >Name</StyledLabel>
          <StyledInput type={"text"} name={"name"} onChange={this.handleChange} id={"name"} required={true} placeholder={"Your name"}/>

          <StyledLabel htmlFor={"email"}>Email</StyledLabel>
          <StyledInput type={"email"} name={"email"} onChange={this.handleChange} id={"email"} required={true} placeholder={"Your email address"}/>

          <StyledLabel htmlFor={"message"}>Message</StyledLabel>
          <StyledTextarea name={"message"} onChange={this.handleChange} rows={7} id={"message"} required={true} placeholder={"Write your message here"} />

          <Button type="submit">Send Message →</Button>

        </form>
        </div>
    );
  }
}

export default Contact